#okta-sign-in.auth-container .button-primary {
    background: -webkit-linear-gradient(#304075, #304075);
}

#okta-sign-in.auth-container .button-primary:hover,
#okta-sign-in.auth-container .button-primary:focus,
#okta-sign-in.auth-container .button-primary:active,
#okta-sign-in.auth-container .button-primary:focus {
    background: -webkit-linear-gradient(#475686, #475686);
    background-color: #304075;
}

#okta-sign-in.auth-container .button.button-primary.link-button-disabled {
    /* -- Buttons' Colors -- */
    color: #fff;
    background-color: #475686;
    border-color: #304075;
    background-image: none;
    box-shadow: none;
}